<template>
  <b-navbar data-cy="navbar" toggleable="md" type="dark" class="jh-navbar">
    <b-navbar-brand class="logo" b-link to="/">
      <span class="logo-img"></span>
      <span v-text="$t('global.title')" class="navbar-title">magazzino</span> <span class="navbar-version">{{ version }}</span>
    </b-navbar-brand>
    <b-navbar-toggle
      right
      class="jh-navbar-toggler d-lg-none"
      href="javascript:void(0);"
      data-toggle="collapse"
      target="header-tabs"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <font-awesome-icon icon="bars" />
    </b-navbar-toggle>

    <b-collapse is-nav id="header-tabs">
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/" exact>
          <span>
            <font-awesome-icon icon="home" />
            <span v-text="$t('global.menu.home')">Home</span>
          </span>
        </b-nav-item>
        <b-nav-item-dropdown
          right
          id="registry-menu"
          v-if="authenticated && hasAnyAuthority([ADMIN, MANAGER, TECHNICIAN])"
          active-class="active"
          class="pointer"
          data-cy="entity"
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="address-book" />
            <span class="no-bold" v-text="$t('global.menu.entities.registry')">Registry</span>
          </span>
          <registry-menu />
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          right
          id="product-menu"
          v-if="authenticated && hasAnyAuthority([ADMIN, MANAGER, TECHNICIAN])"
          active-class="active"
          class="pointer"
          data-cy="entity"
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="shirt" />
            <span class="no-bold" v-text="$t('global.menu.entities.product')">Product</span>
          </span>
          <product-menu />
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          right
          id="warehouse-menu"
          v-if="authenticated && hasAnyAuthority([ADMIN, TECHNICIAN, MANAGER, WAREHOUSE_WORKER])"
          active-class="active"
          class="pointer"
          data-cy="entity"
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="warehouse" />
            <span class="no-bold" v-text="$t('global.menu.entities.warehouse')">Warehouse</span>
          </span>
          <warehouse-menu />
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          right
          id="notification-menu"
          v-if="authenticated && hasAnyAuthority([ADMIN, MANAGER, TECHNICIAN])"
          active-class="active"
          class="pointer"
          data-cy="entity"
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="bell" />
            <span class="no-bold" v-text="$t('global.menu.entities.notification')">Notification</span>
          </span>
          <notification-menu />
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right id="info-menu" v-if="authenticated" active-class="active" class="pointer" data-cy="entity">
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="line-chart" />
            <span class="no-bold" v-text="$t('global.menu.entities.info')">Info</span>
          </span>
          <info-menu />
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          right
          id="admin-menu"
          v-if="hasAnyAuthority('ROLE_ADMIN') && authenticated"
          :class="{ 'router-link-active': subIsActive('/admin') }"
          active-class="active"
          class="pointer"
          data-cy="adminMenu"
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="users-cog" />
            <span class="no-bold" v-text="$t('global.menu.admin.main')">Administration</span>
          </span>
          <b-dropdown-item to="/setting">
            <font-awesome-icon icon="gears" />
            <span v-text="$t('global.menu.entities.setting')">Setting</span>
          </b-dropdown-item>
          <b-dropdown-item to="/import-magazzino">
            <font-awesome-icon icon="file-import" />
            <span v-text="$t('global.menu.entities.import')">Import</span>
          </b-dropdown-item>
          <b-dropdown-item to="/language">
            <font-awesome-icon icon="flag" />
            <span v-text="$t('global.menu.entities.language')">Language</span>
          </b-dropdown-item>
          <b-dropdown-item to="/admin/tracker" active-class="active">
            <font-awesome-icon icon="eye" />
            <span v-text="$t('global.menu.admin.tracker')">User tracker</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="openAPIEnabled" to="/admin/docs" active-class="active">
            <font-awesome-icon icon="book" />
            <span v-text="$t('global.menu.admin.apidocs')">API</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="!inProduction" href="./h2-console/" target="_tab">
            <font-awesome-icon icon="database" />
            <span v-text="$t('global.menu.admin.database')">Database</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <!--        <b-nav-item-dropdown id="languagesnavBarDropdown" right v-if="languages && Object.keys(languages).length > 1">-->
        <!--          <span slot="button-content">-->
        <!--            <font-awesome-icon icon="flag" />-->
        <!--            <span class="no-bold" v-text="$t('global.menu.language')">Language</span>-->
        <!--          </span>-->
        <!--          <b-dropdown-item-->
        <!--            v-for="(value, key) in languages"-->
        <!--            :key="`lang-${key}`"-->
        <!--            v-on:click="changeLanguage(key)"-->
        <!--            :class="{ active: isActiveLanguage(key) }"-->
        <!--          >-->
        <!--            {{ value.name }}-->
        <!--          </b-dropdown-item>-->
        <!--        </b-nav-item-dropdown>-->
        <b-nav-item-dropdown
          right
          href="javascript:void(0);"
          id="account-menu"
          :class="{ 'router-link-active': subIsActive('/account') }"
          active-class="active"
          class="pointer"
          data-cy="accountMenu"
        >
          <span slot="button-content" class="navbar-dropdown-menu">
            <font-awesome-icon icon="user" />
            <span class="no-bold" v-text="$t('global.menu.account.main')"> Account </span>
          </span>
          <b-dropdown-item data-cy="logout" v-if="authenticated" v-on:click="logout()" id="logout" active-class="active">
            <font-awesome-icon icon="sign-out-alt" />
            <span v-text="$t('global.menu.account.logout')">Sign out</span>
          </b-dropdown-item>
          <b-dropdown-item data-cy="login" v-if="!authenticated" v-on:click="openLogin()" id="login" active-class="active">
            <font-awesome-icon icon="sign-in-alt" />
            <span v-text="$t('global.menu.account.login')">Sign in</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script lang="ts" src="./jhi-navbar.component.ts"></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ==========================================================================
    Navbar
    ========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc;
}

.jh-navbar {
  background-color: #353d47;
  padding: 0.2em 1em;
}

.jh-navbar .profile-image {
  margin: -10px 0px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.jh-navbar .dropdown-item.active,
.jh-navbar .dropdown-item.active:focus,
.jh-navbar .dropdown-item.active:hover {
  background-color: #353d47;
}

.jh-navbar .dropdown-toggle::after {
  margin-left: 0.15em;
}

.jh-navbar ul.navbar-nav {
  padding: 0.5em;
}

.jh-navbar .navbar-nav .nav-item {
  margin-left: 1.5rem;
}

.jh-navbar a.nav-link,
.jh-navbar .no-bold {
  font-weight: 400;
}

.jh-navbar .jh-navbar-toggler {
  color: #ccc;
  font-size: 1.5em;
  padding: 10px;
}

.jh-navbar .jh-navbar-toggler:hover {
  color: #fff;
}

@media screen and (min-width: 768px) {
  .jh-navbar-toggler {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}

.navbar-title {
  display: inline-block;
  vertical-align: middle;
  color: white;
}

/* ==========================================================================
    Logo styles
    ========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px;
}

.logo .logo-img {
  height: 45px;
  display: inline-block;
  vertical-align: middle;
  width: 70px;
}

.logo-img {
  height: 100%;
  background: url('../../../content/images/logo.png') no-repeat center center;
  background-size: contain;
  width: 100%;
  filter: drop-shadow(0 0 0.05rem white);
}
</style>
